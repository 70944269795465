/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const seoPropsMapping = (data, defaults = {}) => {
  for (const key in data) {
    if (data[key] == null) {
      if (defaults[key]) data[key] = defaults[key]
      else delete data[key]
    }
  }
  if (defaults.image) {
    defaults.image = defaults.image.replace(/(&[w|h]=)(\d+)/g, "$11.0")
  }

  if (!data.og_image || (data.og_image && data.og_image.fixed == null)) {
    data.og_image = {
      fixed: {
        src: defaults.image,
      },
    }
  }
  if (
    !data.twitter_image_link ||
    (data.twitter_image_link && data.twitter_image_link.fixed == null)
  ) {
    data.twitter_image = {
      fixed: {
        src: defaults.image,
      },
    }
  }

  return {
    title: data.seo_title,
    description: data.seo_description,
    keywords: data.seo_keywords,
    ogDescription: data.og_description,
    image: data.og_image && data.og_image.fixed && data.og_image.fixed.src,
    ogTitle: data.og_title,
    ogType: data.og_type,
    twitterTitle: data.twitter_title,
    twitterSite: data.twitter_site,
    twitterCreator: data.twitter_creator,
    twitterDescription: data.twitter_description,
    twitterImage:
      data.twitter_image_link && data.twitter_image_link.fixed && data.twitter_image_link.fixed.src,
    canonical: data.canonical_url && data.canonical_url.url,
  }
}

const getDescription = description => {
  if (description.length > 160) {
    return `${description.substr(0, 160)}...`
  } else {
    return description
  }
}

const getImage = image => {
  return image ? image : "https://vindicia.com/sites/default/files/vindicia-default-og-image.png"
}

function SEO({
  hideFromIndexing,
  description,
  lang,
  meta,
  title,
  image,
  keywords,
  ogTitle,
  ogType,
  ogDescription,
  twitterCard,
  twitterSite,
  twitterTitle,
  twitterCreator,
  twitterDescription,
  twitterImage,
  canonical,
  children,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )
  const ogImage = getImage(image)
  const twitterImageFull = getImage(twitterImage || image)
  const metaDescription = getDescription(description || site.siteMetadata.description)
  const metaKeywords = keywords ? keywords : "Vindicia, subscription revenue, payments recovery, failed payments recovery"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={canonical ? [{ rel: "canonical", key: canonical, href: canonical }] : []}
      meta={[
        !!hideFromIndexing && {
          name: `robots`,
          content: `noindex`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: (ogTitle || title) + ` | Vindicia`,
        },
        {
          property: `og:description`,
          content: ogDescription || metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: ogType || `website`,
        },
        {
          name: `twitter:card`,
          content: twitterCard || `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: twitterSite || site.siteMetadata.author,
        },
        {
          name: `twitter:creator`,
          content: twitterCreator || site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: (twitterTitle || title) + ` | Vindicia`,
        },
        {
          name: `twitter:description`,
          content: twitterDescription || metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImageFull,
        },
      ].concat(meta)}
    >
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  hideFromIndexing: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  keywords: PropTypes.string,
  ogType: PropTypes.string,
  twitterType: PropTypes.string,
  twitterCreator: PropTypes.string,
  hideFromIndexing: PropTypes.bool,
}

export default SEO
