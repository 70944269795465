import React from "react"
import { Image } from "../VindiciaComponents"
import classnames from "classnames"
import ReactPlayer from "react-player"

const Hero = ({ data, ...props }) => {
  const { hero_text_alignment, hero_vimeo_id, noHero = false } = data

  const heroClass = classnames({
    "uk-flex-between": hero_text_alignment === "right" || hero_text_alignment === "left",
    "uk-margin-large-left": hero_text_alignment === "right",
    "uk-flex uk-width-2-5@m uk-container uk-flex-auto uk-position-relative uk-flex-wrap uk-flex-middle": true,
  })

  const heroTextClass = classnames({
    "margin-bottom": hero_text_alignment === "left",
    "margin-top": hero_text_alignment === "right",
  })

  const heroImageClass = classnames({
    "uk-flex-first@m": hero_text_alignment === "right",
  })

  return (
    <header>
      <div id="product-hero" className="uk-dark hero-copy uk-margin-large-top">
        <div className="uk-container">
          <div className="uk-flex uk-flex-wrap uk-flex-middle" style={{ gap: "15px" }}>
            <div className={heroClass}>
              <div
                className={heroTextClass}
                data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
              >
                <div dangerouslySetInnerHTML={{ __html: data.hero_title.html }} />
                {data.hero_subtitle && <p className="uk-margin-remove-top">{data.hero_subtitle}</p>}
                {data.popup_by_click && data.show_pop_up ? (
                  <button
                    className="uk-button uk-button uk-button-primary uk-border-pill uk-margin-small-bottom"
                    data-uk-toggle={`target: #popup`}
                  >
                    {data.hero_button_label}
                  </button>
                ) : (
                  data.hero_button_link && (
                    <a
                      href={data.hero_button_link}
                      className="uk-button uk-button uk-button-primary uk-border-pill uk-margin-small-bottom"
                      data-uk-scrollspy-class="uk-animation-fade"
                    >
                      {data.hero_button_label}
                    </a>
                  )
                )}
              </div>
              {hero_vimeo_id && (
                <div
                  className="uk-width-2-5@m vimeo-container"
                  data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150"
                >
                  <ReactPlayer
                    url={`https://vimeo.com/${hero_vimeo_id}`}
                    controls={true}
                    width={"100%"}
                  />
                </div>
              )}
            </div>
            <div className={`uk-width-2-5@m ${heroImageClass}`}>
              {!noHero && data.hero_banner_image?.fluid && (
                <Image
                  src={data.hero_banner_image.fluid.src}
                  srcSet={data.hero_banner_image.fluid.srcSet}
                />
              )}
            </div>
          </div>
        </div>
        {/*{data.body && props.page && (
          <div className="localNavigation uk-position-z-index uk-visible@m">
            <div>
              {data.body.map(
                (label, i) =>
                  label.primary &&
                  label.primary.section_name && (
                    <a
                      key={i}
                      className="uk-text-nowrap"
                      href={`#${label.primary.section_id}`}
                      data-uk-scroll="offset:100; duration: 800"
                    >
                      {label.primary.section_name}
                    </a>
                  ),
              )}
            </div>
          </div>
        )}*/}
      </div>
    </header>
  )
}

export default Hero
