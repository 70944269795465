import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
//import TwoColumnGeneric from "../../components/Content/TwoColumnGeneric";
import SEO, { seoPropsMapping } from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../../components/VindiciaComponents"

const Awards = ({ data }) => {
  const section = data.prismicAwardsPage.data
  const [active, setActive] = useState()

  const callback = e => {
    setActive(e.target.id)
    e.persist()
  }

  const seoProps = seoPropsMapping(data.prismicAwardsPage.data)

  return (
    <Layout>
      <SEO {...seoProps} />
      <Hero data={section} />
      <section className="uk-section">
        <div className={`uk-container`}>
          <div className="uk-grid-large" data-uk-grid>
            <div className="uk-width-expand uk-margin-xlarge-bottom">
              <span dangerouslySetInnerHTML={{ __html: section.section_headline.html }} />
              {section.awards.map((award, i) => (
                <div
                  key={award.award_id}
                  id={award.award_id}
                  className="award-entry uk-width-1-1 uk-margin-large"
                >
                  <p className="uk-h4 uk-text-uppercase">{award.headline}</p>
                  <div className="uk-card uk-grid-collapse uk-child-width-1-2@m" data-uk-grid>
                    <div className="uk-card-media-left">
                      <Image
                        src={award.image.fluid.src}
                        srcSet={award.image.fluid.srcSet}
                        alt={award.headline}
                        style={{
                          border: "1px solid #ccc",
                          width: "100%",
                        }}
                      />
                    </div>
                    <div className="uk-card-body">
                      <div dangerouslySetInnerHTML={{ __html: award.description.html }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="uk-width-1-3 uk-margin-xlarge-bottom uk-visible@m">
              <div
                style={{ height: "80%", maxHeight: "80vh", overflowY: "scroll", zIndex: "1" }}
                data-uk-sticky="offset: 100; bottom: #bottom"
              >
                {section.awards.map((award, i) => (
                  <div
                    key={`award-${i}`}
                    className={`${
                      active === "trigger-" + award.award_id ? "isActive" : ""
                    } award-nav uk-width-1-1 uk-margin-small uk-border-rounded`}
                  >
                    <p className="uk-padding-small">
                      <a
                        style={{ color: "#000" }}
                        id={`trigger-${award.award_id}`}
                        onClick={e => setActive(e.target.id)}
                        href={`#${award.award_id}`}
                        data-uk-scroll="offset: 100"
                      >
                        {award.headline}
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div id="bottom" />
      </section>
    </Layout>
  )
}

export default withPreview(Awards)

export const awardsQuery = graphql`
  {
    prismicAwardsPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        hero_title {
          html
        }
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        section_headline {
          html
          text
        }
        awards {
          award_id
          headline
          description {
            html
          }
          image {
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
      }
    }
  }
`
